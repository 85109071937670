import React from 'react'
import Slider from 'react-slick'

import './styles.sass'

const features = [
  { image: '/assets/workflow/24-7.svg', title: '24/7 Monitoring and Support' },
  { image: '/assets/workflow/auto-deploy.svg', title: 'Automated Deployments' },
  {
    image: '/assets/workflow/canary.svg',
    title: 'Canary Testing with Automated Rollback',
  },
  {
    image: '/assets/workflow/consulting.svg',
    title: 'Consulting and Advisory Service',
  },
  {
    image: '/assets/workflow/white-glove.svg',
    title: 'White-glove Launch Support',
  },
  { image: '/assets/workflow/auto-scale.svg', title: 'Automated Scaling' },
  {
    image: '/assets/workflow/fault-tolerant.svg',
    title: 'Fault Tolerant Infrastructure',
  },
  { image: '/assets/workflow/uptime.svg', title: '99.95% Uptime Guarantee' },
  { image: '/assets/workflow/waf.svg', title: 'Web Application Firewall' },
  {
    image: '/assets/workflow/cdn.svg',
    title: 'Global Content Delivery Network',
  },
  { image: '/assets/workflow/burst.svg', title: 'Burst Capacity' },
]

const sliderSettings = {
  infinite: true,
  // swipeToSlide: true,
  variableWidth: true,
  // slidesToShow: 3,
  // slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: "linear"
}

const FeatureCarousel = () => (
  <section className="instar-feature-carousel center">
    {/* <h2>Speed up your workflow.</h2> */}
    <Slider {...sliderSettings}>
      {features.map((ft, index) => (
        <div className="feature" key={`feature-${index}`}>
          <img src={ft.image} alt={ft.title} />
          <div className="title">{ft.title}</div>
        </div>
      ))}
    </Slider>
  </section>
)

export default FeatureCarousel
