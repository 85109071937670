import React from 'react'
import Slider from 'react-slick'

import './styles.sass'

export default class TestimonialTeasers extends React.Component {
  constructor(props) {
    super(props)

    this.testimonials = [
      {
        client: 'Dental 99',
        name: 'Co-Founder and Director',
        quote:
          'We’ve been really impressed with the advanced features Ironstar provides. Being able to use Config Sets to carefully manage and audit changes to our infrastructure is especially useful.',
        image: null,
      },
      // {
      //   client: 'Company Alpha',
      //   name: 'Peter Nguyen, Digital Director',
      //   quote:
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //   pullout: '',
      //   image: '/assets/clients/example.png',
      // },
    ]

    this.sliderSettings = {
      dots: true,
      arrows: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      pauseOnFocus: true,
      accessibility: true,
    }

    this.state = {
      frameIndex: 0,
    }
  }

  render() {
    return (
      this.testimonials.length > 0 && (
        <div className="text-center">
          <h2 className="h6 mt-5 mb-4">What our clients say</h2>

          <Slider {...this.sliderSettings}>
            {this.testimonials.map((item, index) => (
              <div className="slide" key={`testimonial-${index}`}>
                <blockquote className="testimonial">
                  {item.image && (
                    <span className="testimonial__image">
                      <img src={item.image} alt={item.client} />
                    </span>
                  )}
                  <p className="testimonial__quote">{item.quote}</p>
                  <span className="testimonial__author">
                    {item.name}
                    <br className="d-sm-none" /> &ndash; {item.client}
                  </span>
                </blockquote>
              </div>
            ))}
          </Slider>
        </div>
      )
    )
  }
}
