import React from 'react'
import $ from 'jquery'
import { Link } from 'gatsby'

import SuperWaypoint from '../SuperWaypoint'
import './styles.sass'

class Informatics extends React.Component {
  componentDidMount() {
    $('#btn-contact').hover(
      () => {
        $('.instar-setup-today').addClass('hover')
      },
      () => {
        $('.instar-setup-today').removeClass('hover')
      }
    )
  }

  render() {
    return (
      <section>
        <div className="instar-setup-today container-fluid" id="setup-today">
          <SuperWaypoint
            elementId="partner"
            prevElementId="technical-feat"
          />
          <div className="landmark" name="partner" id="partner" />
          <div className="row">
            <div className="col-12 text-center">
              <img src="/assets/brandmark.svg" alt="ironstar" />
              <h2>Set up your hosting today</h2>
              <Link 
                to="/contact" 
                className="btn btn-outline-primary"
              >                
                Get in touch
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="hidden instar-partnerships container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Ironstar partnerships</h3>
              <div className="logos">
                <div className="logo">
                  <img
                    src="/assets/logo-googledevelopers.svg"
                    alt="Google Developers"
                  />
                </div>
                <div className="logo">
                  <img src="/assets/logo-drupal.svg" alt="Drupal" />
                </div>
                <div className="logo">
                  <img src="/assets/logo-github.svg" alt="GitHub" />
                </div>
                <div className="logo">
                  <img src="/assets/logo-microsoft.svg" alt="Microsoft" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="instar-resources container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 documentation dynamic-hover">
              <Link to="/docs" className="big-link">
                Documentation
              </Link>
              <p>An overview of Ironstar services</p>
              <div className="bg" />
            </div>
            <div className="col-12 col-md-6 blog dynamic-hover">
              <Link to="/blog" className="big-link">
                Blog
              </Link>
              <p>
                Latest:{' '}
                <Link to="/blog/the-hidden-cost-decoupling">
                  The Hidden Costs of Decoupling
                </Link>
              </p>
              <div className="bg" />
            </div>
          </div>
      </div> */}
      </section>
    )
  }
}

export default Informatics
