import React from 'react'
// import { graphql, useStaticQuery } from 'gatsby'

import Typing from './Typing'
import BannerNav from '../BannerNav'
import HomeNavData from '../SubNav.yaml'
import { BlogTeaser } from '../../Teasers/Blog'

import './styles.sass'

const Banner = () => {
  // const { markdownRemark } = useStaticQuery(graphql`
  //   query {
  //     markdownRemark(frontmatter: { isBannerFeature: { eq: true } }) {
  //       frontmatter {
  //         slug
  //         category
  //         teaser_title
  //         teaser
  //         image
  //       }
  //     }
  //   }
  // `)

  const featuredArticle = {
    "slug": "/devsurvey24/",
    "category": [
        "Drupal",
        "Community"
    ],
    "teaser_title": "2024 Drupal Developer Survey Results",
    "teaser": "We take a closer look at the people who build Drupal sites as well as the tools used -- Read on for the full results.",
    "image": "/assets/stock/andrew-neel-cckf4TsHAuw-unsplash.small.jpg"
  }

  return (
    <section className="banner">
      <div className="container">
        <div className="row">
          <div className="col-md">
            <div className="heading">
              We are
              <Typing />
            </div>
            <p>
              Ironstar offer more than premium web hosting solutions. <br />
              We are your partner in delivering digital experiences.
            </p>
            <BannerNav subNavData={HomeNavData} />
          </div>
          {featuredArticle && featuredArticle.teaser_title && (
            <div className="col-md col-lg-5 d-flex align-items-end">
              <div className="">
                <h2 className="h6 mb-5">Latest News</h2>
                <BlogTeaser
                  feature="true"
                  variation="highlight"
                  url={featuredArticle.slug}
                  tags={(featuredArticle.category || [])
                    .filter(Boolean)
                    .join(', ')}
                  title={featuredArticle.teaser_title}
                  description={featuredArticle.teaser}
                  background={featuredArticle.image}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Banner
