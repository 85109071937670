import React from 'react'
// import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { faFileAlt } from '@fortawesome/free-solid-svg-icons'

import SuperWaypoint from '../../SuperWaypoint'
import './styles.sass'

const features = [
  {
    image: '/assets/partnership.svg',
    title: 'A partnership',
    description:
      "We're focused on delivering for you. We know that by protecting and accelerating your website, we both will win",
  },
  {
    image: '/assets/backups.svg',
    title: 'Enterprise-grade backups',
    description:
      'Multiple redundant backup services take copies of your data every day, as well as before each production deployment, coupled with long archive durations, ensure your data is safe no matter what.',
  },
  {
    image: '/assets/global-network.svg',
    title: 'Global network',
    description:
      'Choose any of six fault-tolerant global hosting centres to ensure your application and data is stored closest to your visions.',
  },
  {
    image: '/assets/built-for-speed.svg',
    title: 'Built for speed',
    description:
      'Offer your visitors unparalleled performance with multiple content caching and acceleration layers, including a global content delivery network.',
  },
  {
    image: '/assets/complete-security.svg',
    title: 'Complete security',
    description:
      'Our products will default to the highest security settings practical. Where our competitors provide certain security features as optional extras, we will include these as defaults.',
  },
  {
    image: '/assets/we-makers.svg',
    title: 'We are makers',
    description:
      "We've created multiple open-source platforms. We're seriously passionate about hosting.",
  },
]
const tokaidoDescription =
  "Tokaido is a local development and environment manager for Drupal websites proudly developed by us. It's easy to use, lightweight and robust allowing you to launch your environment in less than 30 seconds with zero-configuration."

const MoreThanHosting = () => (
  <section className="instar-more-than-hosting">
    <SuperWaypoint elementId="technical-feat" prevElementId="who-we-are" />
    <div className="landmark" name="technical-feat" id="technical-feat" />

    <div className="container">
      <div className="row heading">
        <div className="col-12 text-center">
          <p className="h6">Explore Ironstar</p>
          <h2 className="h2">More than just web hosting.</h2>
          {/* <Link to="/docs" className="link moon-light">
            <FontAwesomeIcon icon={faFileAlt} className="mr-3 icon" />
            Read our documentation
          </Link> */}
        </div>
      </div>
      <div className="row features">
        {features.map((feature, index) => (
          <div
            className="col-12 col-sm-6 col-md-4 text-center"
            key={`feature-${index}`}
          >
            <div className="pic">
              <img src={feature.image} alt={feature.title} />
            </div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <div className="row separator">
        <div className="col-12 liner">
          <div className="left line" />
          <div className="brandmark text-center">
            <img src="/assets/brandmark.svg" alt="ironstar" />
          </div>
          <div className="right line" />
        </div>
        <div className="col-12 plus text-center">
          <span>+</span>
        </div>
      </div>
      <div className="row tokaido">
        <div className="col-12 text-center">
          <div className="pic">
            <img src="/assets/tokaido.svg" alt="Tokaido" />
          </div>
          <h3>Tokaido</h3>
          <p>{tokaidoDescription}</p>
          <a href="https://tokaido.io" className="link moon-light">
            Learn more about Tokaido
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-3 icon" />
          </a>
          <div className="domain">tokaido.io</div>
        </div>
      </div>
    </div>
  </section>
)

export default MoreThanHosting
