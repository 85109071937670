import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import { activateWayPoint } from '../../utils'

import './styles.sass'

class SuperWaypoint extends React.Component {
  constructor(props) {
    super(props)

    this.processWaypointEnter = this.processWaypointEnter.bind(this)
    this.processWaypointLeave = this.processWaypointLeave.bind(this)
  }

  processWaypointEnter({ previousPosition }) {
    const { elementId } = this.props

    if (previousPosition !== 'below') {
      return
    }

    activateWayPoint(elementId)
  }

  processWaypointLeave({ currentPosition }) {
    const { prevElementId } = this.props

    if (currentPosition !== 'below') {
      return
    }

    activateWayPoint(prevElementId)
  }

  render() {
    const { topOffset = 400 } = this.props

    return (
      <div className="waypoint-px" style={{ top: topOffset }}>
        <Waypoint
          fireOnRapidScroll={true}
          onEnter={this.processWaypointEnter}
          onLeave={this.processWaypointLeave}
        />
      </div>
    )
  }
}

SuperWaypoint.propTypes = {
  topOffset: PropTypes.number,
  elementId: PropTypes.string,
  prevElementId: PropTypes.string,
}

export default SuperWaypoint
