import React from 'react'
import ReactSVG from 'react-svg'

import Accordion from '../../Accordion'
import SuperWaypoint from '../../SuperWaypoint'
import SuperStack from '../../SuperStack'

import './styles.sass'

class SellFrameGamma extends React.Component {
  constructor(props) {
    super(props)

    this.changeFrame = this.changeFrame.bind(this)

    this.frames = [
      {
        title: 'Seamless integration and scaling',
        description:
          'We work with you do develop a custom solution that anticipates your growth and traffic patterns, and scales to meet increases in demand and seasonal fluctuations.',
      },
      {
        title: 'Manage and automate',
        description:
          'We can help build and maintain your platform, and empower your team to rapidly deliver new features and functionality to market with automated deployment and management workflows.',
      },
      {
        title: 'Operational integrity',
        description:
          'As an enterprise-grade provider, we provision robust and always-on infrastructure. Our specialist team provides support 24 hours a day, 365 days a year with automated alerting.',
      },
    ]

    this.state = {
      frameIndex: 0,
    }
  }

  changeFrame(newIndex) {
    setTimeout(() => {
      this.setState({
        frameIndex: newIndex,
      })
    }, 0)
  }

  render() {
    const { frameIndex } = this.state

    return (
      <section className="instar-sell-frame-gamma">
        <SuperWaypoint
          elementId="how-we-integrate"
          prevElementId="who-we-are"
        />
        <div
          className="landmark"
          name="how-we-integrate"
          id="how-we-integrate"
        />
        <div className="container-fluid narrow-contents d-md-none">
          <div className="row sole-pic">
            <img
              src="/assets/sells/static.jpg"
              alt="Integrate. Operate. Secure."
            />
          </div>
          <div className="row">
            <div className="col-12">
              <p className="heading h6">Integrate. Operate. Secure.</p>
            </div>
            <ReactSVG
              className="mobile-stacks"
              src="/assets/mobile-stacks.svg"
            />
            {this.frames.map((frame, index) => (
              <div className="col-10 frame" key={`frame-${index}`}>
                <h2>{frame.title}</h2>
                <p>{frame.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="container-fluid contents d-none d-md-flex">
          <div className="row">
            <div className="d-none col-xl-2 d-xl-flex accordion-nav">
              <ul>
                {this.frames.map((frame, index) => (
                  <li
                    data-header={frame.title}
                    key={`frame-selector-${index}`}
                    className={index === frameIndex ? 'active' : ''}
                  >
                    <button
                      onClick={() => {
                        this.changeFrame(index)
                      }}
                    >
                      {index}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6 col-xl-4 accordion-wrapper">
              <p className="heading h6">Integrate. Operate. Secure.</p>
              <Accordion
                className="accordion"
                selectedIndex={frameIndex}
                onChange={(index, expanded, selectedIndex) =>
                  this.changeFrame(selectedIndex)
                }
              >
                {this.frames.map((frame, index) => (
                  <div
                    data-header={frame.title}
                    key={`frame-${index}`}
                    className="accordion-item"
                  >
                    {frame.description}
                  </div>
                ))}
              </Accordion>
            </div>
            <div className="col-4 stack">
              <SuperStack activeCardIndex={frameIndex} />
            </div>
            <div className="d-none col-2 pics d-lg-flex">
              <div
                className="illustrate"
                style={{ backgroundImage: 'url(/assets/sells/static.jpg)' }}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SellFrameGamma
