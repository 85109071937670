import React from 'react'

import './styles.sass'

export default class ImageTeaser extends React.Component {
  render() {
    const { image, imageTitle, caption } = this.props

    return (
      <>
        <h2 className="h6 mt-5 mb-4">Feature Highlight</h2>

        <div className="d-flex flex-md-column flex-lg-row align-items-stretch">
          <figure className="feature-teaser">
            {image && (
              <div className="image">
                <img src={image} alt={imageTitle} />
              </div>
            )}
            {caption && <figcaption>{caption}</figcaption>}
          </figure>
        </div>
      </>
    )
  }
}
