import React from 'react'
import ReactSVG from 'react-svg'

import './styles.sass'

const SuperStack = ({ activeCardIndex = 0 }) => (
  <div className={`instar-super-stack active-${activeCardIndex}`}>
    <div className="card-0">
      <ReactSVG
        src="/assets/stack-card.svg"
      />
      <img src="/assets/stack-icon-0.svg" alt="Integrate & scale" />
    </div>
    <div className="card-1">
      <ReactSVG
        src="/assets/stack-card.svg"
      />
      <img src="/assets/stack-icon-1.svg" alt="Manage & automate" />
    </div>
    <div className="card-2">
      <ReactSVG
        src="/assets/stack-card.svg"
      />
      <img src="/assets/stack-icon-2.svg" alt="Operational integrity" />
    </div>
    <div className="card-base">
      <ReactSVG
        src="/assets/stack-base.svg"
      />
    </div>
    <div className="line"></div>
  </div>
);

export default SuperStack
