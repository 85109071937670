import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import siteData from '../../../content/siteData.yaml'

import SuperWaypoint from '../../SuperWaypoint'
// import BlogTeasers from '../../Teasers/Blogs'
import ImageTeaser from '../../Teasers/Feature'
import TestimonialTeasers from '../../Teasers/Testimonials'

import './styles.sass'

class HomeWhoWeAre extends React.Component {
  constructor(props) {
    super(props)

    this.changeFrame = this.changeFrame.bind(this)

    this.platforms = [
      {
        title: 'Drupal',
        image: '/assets/logos/drupal.x300.png',
      },
      {
        title: 'Laravel',
        image: '/assets/logos/laravel.x300.png',
      },
      // {
      //   title: 'Wordpress',
      //   image: '/assets/logos/wordpress.x300.png',
      // },
      {
        title: 'Node.JS',
        image: '/assets/logos/nodejs.190x300.png',
      },
      // {
      //   title: 'Static Sites',
      //   image: '/assets/logos/static.150x350.png',
      // },
      // {
      //   title: 'PHP',
      //   image: '/assets/logos/php.170x300.png',
      // },
      // {
      //   title: 'AWS',
      //   image: '/assets/logos/aws.180x300.png',
      // },
      // {
      //   title: 'Custom',
      //   desc: 'Get in touch to discuss your needs',
      //   // image: '',
      // },
    ]

    this.state = {
      frameIndex: 0,
    }
  }

  changeFrame(oldIndex, newIndex) {
    setTimeout(() => {
      this.setState({
        frameIndex: newIndex,
      })
    }, 0)
  }

  render() {
    return (
      <section className="instar-home-who-we-are">
        <SuperWaypoint elementId="who-we-are" prevElementId="none" />
        <div className="landmark" name="who-we-are" id="who-we-are" />

        <div
          className="bg"
          style={{ backgroundImage: `url(/assets/sells/1-1.jpg)` }}
        />

        <div className="container">
          <div className="row align-items-end">
            <div className="col-12 col-md-5">
              <div className="py-5">
                <h2 className="h2">We are committed</h2>
                <p className="lead">
                  We provide the highest quality web hosting and security
                  services for digital experiences built on the Drupal, Node.js
                  and Laravel platforms. We partner with medium-to-large
                  enterprise and government departments who seek reliability,
                  capability and commitment to their products and services.
                </p>
              </div>
            </div>
            <div className="col-12 offset-md-1 col-md-6 offset-lg-1 col-lg-6 we-are__platforms">
              <h2 className="h6 mt-5 mb-3">Platforms we support</h2>

              <div className="d-flex flex-wrap align-items-stretch justify-content-center text-center c__content">
                {this.platforms.map((item, index) => (
                  <div key={`platforms-${index}`} className="figure flex-fill">
                    <figure className="d-flex flex-column justify-content-center">
                      {item.image && (
                        <div className="image">
                          <img src={item.image} alt={item.title} />
                        </div>
                      )}
                      <figcaption>{item.title}</figcaption>
                      {item.desc && <div className="desc">{item.desc}</div>}
                    </figure>
                  </div>
                ))}
              </div>

              <div className="more">
                Not quite what you're looking for? We might still be able to
                help. <Link to={siteData.links.form}>Discuss your options</Link>
                .
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 order-md-2 col-lg-5 offset-lg-1">
              <div className="py-5">
                <h2 className="h2">Driven by results</h2>
                <p className="lead">
                  Deliver your digital experience with confidence knowing it’s
                  backed by an Australian owned and operated host. We are
                  personally committed to your success and your customer’s
                  needs.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 order-md-1 py-5">
              <ImageTeaser
                imageTitle="Ironstar Console"
                image="/assets/sells/console-login.png"
                caption={
                  <>
                    You're in control, with our easy-to-use self-service portal.{' '}
                    <OutboundLink href={siteData.links.console}>
                      Login to Ironstar Console
                    </OutboundLink>
                    .
                  </>
                }
              />
              {/* <BlogTeasers /> */}
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-5">
              <div className="py-5">
                <h2 className="h2">Trusted expertise</h2>
                <p className="lead">
                  With over 10 years experience in managed services,{' '}
                  {/*we are able to identify complex issues and provide solutions, rather than wait for problems to occur.*/}{' '}
                  our procedures and processes are some of the best in the
                  industry, with a lens on leading security and data integrity
                  standards.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 offset-md-1">
              <TestimonialTeasers />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HomeWhoWeAre
