import React from 'react'
import { Link } from 'gatsby'

// import './styles.sass'

export class BlogTeaser extends React.Component {
  render() {
    const item = this.props

    const classNames = [
      'blog-teaser',
      item.feature === 'true' && 'is--feature',
      item.variation && `v--${item.variation}`,
    ]
      .filter(Boolean)
      .join(' ')

    const useImageTag = item.feature === 'true' || item.variation !== 'fill'

    return (
      <article
        className={classNames}
        style={
          item.background
            ? {
                backgroundImage: 'url(' + item.background + ')',
              }
            : undefined
        }
      >
        <Link to={item.url}>
          {item.image && (
            <div
              className="image"
              style={
                !useImageTag
                  ? {
                      backgroundImage: 'url(' + item.image + ')',
                    }
                  : undefined
              }
            >
              {useImageTag && <img src={item.image} alt={item.title} />}
            </div>
          )}
          <span className="content">
            <span className="tags">{item.tags}</span>
            <span className="title">{item.title}</span>
            <span className="description">{item.description}</span>
          </span>
        </Link>
      </article>
    )
  }
}
