import React from 'react'

import { goToLandmark } from '../../utils'

import './styles.sass'

class SubNav extends React.Component {
  render() {
    const { isSticky = false, subNavData = false, ...passThroughProps } = this.props

    return (
      <section
        className={`instar-sub-nav ${isSticky ? 'sticky' : ''}`}
        {...passThroughProps}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 subnav-links">
              {subNavData.map((item, index) => (
                <a
                  key={index}
                  href={item.path}
                  id={item.id}
                  className="sticky-subnav-item"
                  onClick={evt => {
                    evt.preventDefault()
                    goToLandmark(item.id, 145)
                  }}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SubNav
