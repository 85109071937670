import React from 'react'

import { goToLandmark } from '../../../utils'

import './styles.sass'

class BannerNav extends React.Component {
  render() {
    const { subNavData = false } = this.props

    return (
      <section className="instar-banner-nav">
        <div className="banner-links">
          {subNavData.map((item, index) => (
            <span key={`bannernav-${item.id}-${index}`}>
              <a
                href={item.path}
                onClick={(evt) => {
                  evt.preventDefault()
                  goToLandmark(item.id, 145)
                }}
              >
                {item.title}
              </a>
            </span>
          ))}
        </div>

        <div className="container d-none">
          <div className="row">
            <div className="col-12 banner-links">
              {subNavData.map((item, index) => (
                <span key={`bannernav-${item.id}-${index}`}>
                  <span className="separator" />
                  <a
                    href={item.path}
                    onClick={(evt) => {
                      evt.preventDefault()
                      goToLandmark(item.id, 145)
                    }}
                  >
                    {item.title}
                  </a>
                </span>
              ))}
              {/* <a
                href="#target"
                onClick={evt => {
                  evt.preventDefault()
                  goToLandmark('who-we-are', 145)
                }}
              >
                Who we are
              </a>
              <div className="separator" />
              <a
                href="#target"
                onClick={evt => {
                  evt.preventDefault()
                  goToLandmark('technical-feat', 145)
                }}
              >
                Technical features
              </a>
              <div className="separator" />
              <a
                href="#target"
                onClick={evt => {
                  evt.preventDefault()
                  goToLandmark('solutions', 145)
                }}
              >
                Solutions
              </a>
              <div className="separator" />
              <a
                href="#target"
                onClick={evt => {
                  evt.preventDefault()
                  goToLandmark('partners', 145)
                }}
              >
                Partner with us
              </a> */}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerNav
