import React from 'react'
import Typist from 'react-typist'

import './styles.sass'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.phrases = [
      'security focused',
      'serious Drupal',
      'serious Node.js',
      'serious Laravel',
      'Ironstar',
      'here to help',
    ]
    this.state = {
      typing: true,
    }

    this.typingFinished = this.typingFinished.bind(this)
  }

  typingFinished() {
    this.setState({ typing: false }, () => {
      this.setState({ typing: true })
    })
  }

  render() {
    return (
      <div className="dynamic-typing">
        <div className="enclosure left">{'{'}</div>
        {this.state.typing && (
          <Typist avgTypingDelay={100} onTypingDone={this.typingFinished}>
            {this.phrases.map((phrase, index) => (
              <span key={`phr-${index}-${phrase}`}>
                {phrase}
                <Typist.Backspace count={phrase.length} delay={1000} />
              </span>
            ))}
          </Typist>
        )}
        <div className="enclosure right">{'}'}</div>
      </div>
    )
  }
}

export default Home
