import React from 'react'
import Helmet from 'react-helmet'
import { StickyContainer, Sticky } from 'react-sticky'

import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'
import Banner from '../../components/Home/Banner'
import SubNav from '../../components/SubNav'
import SellFrameGamma from '../../components/Home/SellFrameGamma'
import FeatureCarousel from '../../components/Home/FeatureCarousel'
import MoreThanHosting from '../../components/Home/MoreThanHosting'
import Informatics from '../../components/Informatics'
import HomeWhoWeAre from '../../components/Home/WhoWeAre'
// import HomeFeaturedBlog from '../../components/Home/FeatureBlog'
import HomeNavData from '../../components/Home/SubNav.yaml'

import './styles.sass'

const stickyStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
}

const Home = () => (
  <div className="instar-home-page">
    <Helmet>
      <title>Ironstar - Premium Hosting</title>
      <meta name="description" content="Premium hosting" />
      <meta
        name="keywords"
        content="drupal, hosting, ironstar, node.js, nodejs, laravel"
      />
    </Helmet>

    <PageHeader />
    <Banner subNavData={HomeNavData} />
    <StickyContainer>
      <div>
        <Sticky disableCompensation topOffset={-80}>
          {({ isSticky }) => (
            <SubNav
              style={stickyStyle}
              isSticky={isSticky}
              subNavData={HomeNavData}
            />
          )}
        </Sticky>
        <FeatureCarousel />
        <HomeWhoWeAre />
        {/* <HomeFeaturedBlog /> */}
        <SellFrameGamma />
        <MoreThanHosting />
        <Informatics />
        <PageFooter />
      </div>
    </StickyContainer>
  </div>
)

export default Home
